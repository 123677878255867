<template>
  <div class="category-container">
    <div class="header" v-if="site === 0">
      <el-radio-group v-model="currentSite" @change="changeSite">
        <el-radio-button :label="1">钱包官网</el-radio-button>
        <el-radio-button :label="2">博泉哈希</el-radio-button>
      </el-radio-group>
    </div>
    <el-row class="header" type="flex">
      <el-col :span="18" class="search-option">
        <!-- <el-input v-model="keyword" class="search-input" placeholder></el-input>
        <el-button type="primary" @click="handleSearch">搜索</el-button>-->
      </el-col>
      <el-col :span="6" class="create-option">
        <el-button
          type="primary"
          @click="
            postForm = {};
            showCreateDialog = true;
          "
        >
          添加主题
        </el-button>
      </el-col>
    </el-row>
    <div class="content">
      <el-table :data="categoryList.items" border stripe>
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column
          v-for="col in columns"
          :key="col.id"
          :prop="col.id"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
        ></el-table-column>
        <el-table-column label="Action" width="250" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
            >
              删除
            </el-button>
            <el-button size="mini" type="success" @click="handleTop(scope.row)">
              置顶
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :title="isEdit ? '编辑主题' : '添加主题'"
      :visible.sync="showCreateDialog"
      width="30%"
      @close="(showCreateDialog = false), (isEdit = false)"
    >
      <el-form ref="form" :model="postForm" label-width="80px">
        <el-form-item label="中文主题">
          <el-input v-model="postForm.article_type_name"></el-input>
        </el-form-item>
        <el-form-item label="英文主题">
          <el-input v-model="postForm.article_type_name_en"></el-input>
        </el-form-item>
        <el-form-item label="排序权重">
          <el-input v-model="postForm.show_order" type="number"></el-input>
        </el-form-item>
        <el-form-item label="归属官网" v-if="site === 0">
          <el-radio-group
            v-model="postForm.site"
            :disabled="isEdit ? true : false"
          >
            <el-radio-button :label="1">钱包官网</el-radio-button>
            <el-radio-button :label="2">博泉哈希</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="showCreateDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  postCategory,
  editCategory,
  setTopCategory,
  deleteCategory,
} from "@/api/news";

const SITE_TYPE = {
  1: "钱包官网",
  2: "博泉哈希",
  3: "恒境 NFT",
};

export default {
  props: {
    site: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      keyword: "",
      showCreateDialog: false,
      isEdit: false,
      postForm: {},
      columns: [
        {
          id: "article_type_name",
          label: "Theme",
        },
        {
          id: "create_time",
          label: "Date",
        },
        {
          id: "site",
          label: "Belong",
          formatter: (row) => {
            return SITE_TYPE[row.site];
          },
        },
      ],
      currentSite: 1,
    };
  },
  computed: {
    ...mapGetters(["categoryList"]),
  },
  mounted() {
    if (this.site) {
      this.currentSite = this.site;
    }

    this.fetchData();
  },
  methods: {
    fetchData() {
      const query = { page: 1, size: 20, site: this.currentSite };
      if (this.keyword) {
        query.keyword = this.keyword;
      }
      this.$store.dispatch("news/getCategoryList", query);
    },
    changeSite(val) {
      this.currentPage = 1;
      this.pageSize = 30;
      this.fetchData();
    },
    handleEdit(data) {
      this.showCreateDialog = true;
      this.isEdit = true;
      this.postForm = {
        id: data.id,
        article_type_name: data.article_type_name,
        article_type_name_en: data.article_type_name_en,
        show_order: data.show_order,
        site: data.site,
      };
    },
    handleDelete(category) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteCategory(category.id).then(() => {
          this.$message({
            message: "删除成功",
            duration: 3000,
            type: "success",
          });
          this.fetchData();
        });
      });
    },
    handleTop(category) {
      setTopCategory(category.id).then(() => {
        this.$message({ message: "置顶成功", duration: 3000, type: "success" });
        this.fetchData();
      });
    },
    handleSearch() {
      this.fetchData();
    },
    onSubmit() {
      const query = {
        site: this.site,
        ...this.postForm,
      };

      if (this.isEdit) {
        editCategory(query).then((data) => {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "success",
          });
          this.handleSuccess();
        });
      } else {
        postCategory(query).then((data) => {
          this.$message({
            message: data.data.msg,
            duration: 3000,
            type: "success",
          });
          this.handleSuccess();
        });
      }
    },
    handleSuccess() {
      this.showCreateDialog = false;
      this.postForm = {};
      this.isEdit = false;
      this.fetchData();
    },
  },
};
</script>

<style lang="less" scoped>
.category-container {
  margin: 0;

  .header {
    margin: 20px;
  }

  .search-input {
    width: 300px;
  }

  .create-option {
    display: flex;
    justify-content: flex-end;
  }

  .content {
    padding: 0 20px 20px;
  }
}
</style>
